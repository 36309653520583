import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { get } from "lodash"
import SideColumnWrapper from "../side-column-wrapper/side-column-wrapper"
import {
  ExternalLink,
  SideColumnDownloadImage,
  SideColumnTitle,
  SideColumnText,
  body,
} from "./cta-download.module.css"

import linkBuilder from "../../../modules/linkBuilder"
import { Link } from "gatsby"
import { trackEvent } from "../../../modules/analytics"
import { eventTracking } from "../../../services/tracking"

//import DownloadForm from "../../download-form/download-form"

const CtaDownload = ({
  cta,
  position,
  displayPosition,
  displayPageContentfulId,
  displayPageTitle,
  displayPagePath,
}) => {
  const desc = get(cta, "description.childMarkdownRemark.rawMarkdownBody")

  return (
    <SideColumnWrapper>
      <div className={ExternalLink}>
        <div className={body}>
          {(cta?.showSidebarCtaImage ?? false) && (
            <>
              {cta?.image?.gatsbyImageData ? (
                <GatsbyImage
                  image={cta.image?.gatsbyImageData}
                  className={SideColumnDownloadImage}
                  alt={cta.image.title ? cta.image.title : ""}
                />
              ) : (
                <img
                  src={cta.image?.file.url}
                  className={SideColumnDownloadImage}
                  alt={cta.image.title ? cta.image.title : ""}
                />
              )}
            </>
          )}

          <p className={SideColumnTitle}>{cta.title}</p>

          {cta?.description?.childMarkdownRemark && (
            <p className={SideColumnText}>{desc}</p>
          )}
        </div>
        <Link
          to={linkBuilder.download(cta)}
          className={`download-form-submit`}
          onClick={() => {
            eventTracking("click", {
              contentfulId: cta.contentful_id,
              contentfulType: "download",
              contentfulTitle: cta.title,
              displayPageContentfulId: displayPageContentfulId,
              displayPageContentfulTitle: displayPageTitle,
              displayPosition: displayPosition,
              displayPagePath: displayPagePath,
            })
            trackEvent("cta_sidenav_download_link")
          }}
        >
          {cta.downloadType === "Whitepaper" ? "Download" : "Contactformulier"}
        </Link>
      </div>
    </SideColumnWrapper>
  )
}

export default CtaDownload
