import React from "react"
import SideColumnWrapper from "../side-column-wrapper/side-column-wrapper"
import { Link } from "gatsby"
import { tags as tagsClass, active } from "./tags.module.css"
import linkBuilder from "../../../modules/linkBuilder"

const Tags = ({ tags }) => {
  return (
    <SideColumnWrapper>
      <div className={tagsClass}>
        <h5>Actuele dossiers</h5>

        <ul id="tag-links">
          {tags
            .filter((_, index) => index !== 0)
            .map((tag, index) => {
              return (
                <li key={`tag-links-${index}`}>
                  <Link activeClassName={active} to={linkBuilder.tag(tag)}>
                    {tag.name}
                  </Link>
                </li>
              )
            })}
          <li>
            <Link to="/dossiers/">Alle dossiers</Link>
          </li>
        </ul>
      </div>
    </SideColumnWrapper>
  )
}

export default Tags
