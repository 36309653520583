import React from "react"
import { aside } from "./side-column.module.css"
import CtaDownload from "./cta-download/cta-download"
import CtaExternalLink from "./cta-external-link/cta-external-link"
import CtaNewsLetter from "./cta-newsletter/cta-newsletter"
import CtaSearch from "./cta-search/cta-search"
import CtaSponsorInfo from "./cta-sponsor-info/cta-sponsor-info"
import Tags from "./tags/tags"
import DisplayTracking from "../tracking/display-tracking"

const SideColumn = ({
  className,
  callToActions,
  tags,
  pageContentfulId,
  pageTitle,
  pagePath,
  showSponsorInfo
}) => {
  // Only use CTAs when type is created/designed
  if (callToActions)
    callToActions = callToActions.filter((cta) => {
      return (
        cta.__typename === "ContentfulExternalLink" ||
        cta.__typename === "ContentfulDownload"
      )
    })

  return (
    <aside className={className ? `${aside} ${className}` : aside}>
      {callToActions &&
        callToActions.length > 0 &&
        callToActions.map((cta, index) => {
          if (cta.__typename === "ContentfulExternalLink")
            return (
              <CtaExternalLink
                position={index + 1}
                key={`cta-${index}`}
                cta={cta}
              />
            )
          else
            return (
              <DisplayTracking
                key={`cta-${index}`}
                contentfulId={cta.contentful_id}
                contentTitle={cta.title}
                contentType="download"
                displayPosition="sideColumn"
                displayPageContentfulId={pageContentfulId}
                displayPageTitle={pageTitle}
                displayPagePath={pagePath}
                formFields={cta.formFields}
              >
                <CtaDownload
                  position={index + 1}
                  cta={cta}
                  displayPagePath={pagePath}
                  displayPageTitle={pageTitle}
                  displayPageContentfulId={pageContentfulId}
                />
              </DisplayTracking>
            )
        })}

      {showSponsorInfo && <CtaSponsorInfo position={1} />}

      {((callToActions && callToActions.length <= 1) ||
        (!callToActions && !showSponsorInfo)) && <CtaNewsLetter position={2} />}

      {callToActions && callToActions.length <= 2 && !tags && (
        <CtaSearch position={3} />
      )}

      {tags && <Tags tags={tags} />}

    </aside>
  )
}

export default SideColumn
