import React from "react"
import {
  AdverterenAndSideWrapper,
  AdverterenWrapper,
  adverterenRow,
  adverterenIntro,
  imageWrapperWebsite,
  cijfers,
  border,
  item,
  cijfer,
  tableWrapper,
  table,
  thead,
  tr,
  th,
  td,
  AdverterenAside,
} from "./adverteren.module.css"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

/* Components */
import Seo from "../components/seo/seo"
import Layout from "../components/layout"
import Footer from "../components/footer/footer"
import StickyNav from "../components/sticky-nav/sticky-nav"
import SideColumn from "../components/side-column/side-column"

const Adverteren = ({ data }) => {
  const navigationDesks = data.navigationDesks.nodes;
  return (
    <Layout>
      <header className={"header"}>
        <StickyNav desks={navigationDesks} />
      </header>
      <main className="main">
        <div className={`container clearfix`}>
          <Seo title="Adverteren op MKB Servicedesk" />
          <div className={AdverterenAndSideWrapper}>
            <div className={AdverterenWrapper}>
              <h1>Mediakit MKB Servicedesk</h1>
              <div className={adverterenRow}>
                <div className={"adverterenCol"}>
                  <div className={adverterenIntro}>
                    <p>
                      <b>
                        MKB Servicedesk staat ondernemend Nederland al ruim 15
                        jaar bij met raad en daad. MKBServicedesk.nl is een
                        vraaggestuurd ondernemersplatform met praktische how-to
                        content. Wij organiseren betrouwbare, concrete en
                        betaalbare antwoorden op alle ondernemersvragen. Dit
                        doen wij samen met gerenommeerde kennispartners.
                      </b>
                    </p>

                    <p>
                      Gezamenlijk willen we iedere ondernemer in Nederland
                      kennen en begrijpen zodat we hem in elke fase van zijn
                      ondernemerschap vooruit kunnen helpen. Met de juiste
                      content en tools.
                    </p>

                    <p>
                      <b>Kennispartner worden of adverteren?</b>
                      <br />
                      <Link to="mailto:sales@mkbservicedesk.nl">
                        Stuur een mail
                      </Link>{" "}
                      of bel 088-6520009
                    </p>
                  </div>{" "}
                  {/* adverteren intro */}
                </div>
                <div className={"adverterenCol"}>
                  <div className={imageWrapperWebsite}>
                    <GatsbyImage
                      image={data.fileHome.childImageSharp.gatsbyImageData}
                      style={{ width: "100%" }}
                      alt=""
                    />
                  </div>
                </div>
              </div>{" "}
              {/*adverterenRow */}
              <div className={`${cijfers} ${border}`}>
                <div className={item}>
                  <div className={cijfer}>200K</div>
                  <div className={"txt"}>
                    Unieke bezoekers per maand op mkbservicedesk.nl
                  </div>
                </div>
                <div className={item}>
                  <div className={cijfer}>70K</div>
                  <div className={"txt"}>Leden in community</div>
                </div>
                <div className={item}>
                  <div className={cijfer}>30K</div>
                  <div className={"txt"}>Social media fans</div>
                </div>
                <div className={item}>
                  <div className={cijfer}>38K</div>
                  <div className={"txt"}>Ontvangers nieuwsbrief</div>
                </div>
              </div>
              <div className={"nieuwsbrief"}>
                <h2>Adverteren</h2>
                <p>
                  <b>Digitale nieuwsbrief van MKB Servicedesk</b>
                </p>
              </div>
              <div className={`${adverterenRow} ${border}`}>
                <div className={"adverterenCol"}>
                  <div className={adverterenIntro}>
                    <div className={tableWrapper}>
                      <table className={table}>
                        <thead className={thead}>
                          <tr className={tr}>
                            <th className={th}>ONDERDEEL</th>
                            <th className={th}>Prijs</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr className={tr}>
                            <td className={td}>
                              Advertorial in reguliere nieuwsbrief op dinsdag
                            </td>
                            <td className={td}>€2.000,-</td>
                          </tr>
                          <tr className={tr}>
                            <td className={td}>
                              Volledige themanieuwsbrief op donderdag
                            </td>
                            <td className={td}>€4.500,-</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>{" "}
                  {/* adverteren intro */}
                </div>
                <div className={"adverterenCol"}>
                  <h3>Productie</h3>
                  <ul>
                    <li>Gezamenlijk gesprek over inhoud &amp; propositie</li>
                    <li>MKB Servicedesk doet voorstel voor uiting</li>
                    <li>Inhoudelijke boodschap als basis</li>
                    <li>Commercieel aanbod ondergeschikt</li>
                    <li>Goedkeuring &amp; verzending</li>
                  </ul>
                </div>
              </div>{" "}
              {/*adverterenRow */}
              <h2>Doelgroep</h2>
              <div className={`${adverterenRow} ${border}`}>
                <div className={"adverterenCol"}>
                  <div className={adverterenIntro}>
                    <p>
                      <b>
                        Bij al onze communicatie, content en publicaties ligt de
                        focus op de ondernemer en het mkb. Welke onderwerpen,
                        welke informatie en welke uitdagingen zijn er? Om daar
                        optimaal bij aan te sluiten, doen wij structureel
                        onderzoek en vragen wij wat er speelt en leeft in de
                        doelgroep.
                      </b>
                    </p>
                  </div>{" "}
                  {/* adverteren intro */}
                </div>
                <div className={"adverterenCol"}>
                  <GatsbyImage
                    image={data.fileSmb.childImageSharp.gatsbyImageData}
                    style={{ width: "100%" }}
                    alt=""
                  />
                </div>
              </div>{" "}
              {/*adverterenRow */}
              <div className={"kennispartner"}>
                <h2>Kennispartner worden</h2>
              </div>
              <div className={`${adverterenRow} ${border}`}>
                <div className={"adverterenCol"}>
                  <div className={adverterenIntro}>
                    <p>
                      <b>
                        ‘Wij organiseren betrouwbare, concrete en betaalbare
                        antwoorden op alle ondernemersvragen. Dit doen wij samen
                        met gerenommeerde kennispartners. Tijdens een
                        structurele samenwerking zorgen we voor een gezamenlijke
                        campagne waarin we ondernemers verder helpen.
                      </b>
                    </p>

                    <p>
                      Met behulp van de aanwezige kennis bij de kennispartner
                      zorgen we voor een mooie mix van content op MKB
                      Servicedesk, die ondernemers direct verder helpt en/of
                      naar de kennispartner leidt. Aan de hand van diverse
                      soorten content nemen we de ondernemer stap voor stap mee
                      in het proces van bewustwording tot daadwerkelijke actie.’
                    </p>

                    <p>
                      <b>Kennispartner worden of adverteren?</b>
                      <br />
                      <Link to="mailto:sales@mkbservicedesk.nl">
                        Stuur een mail
                      </Link>{" "}
                      of bel 088-6520009
                    </p>
                  </div>{" "}
                  {/* adverteren intro */}
                </div>
                <div className={"adverterenCol"}>
                  <GatsbyImage
                    image={data.fileDesk.childImageSharp.gatsbyImageData}
                    style={{ width: "100%" }}
                    alt=""
                  />
                </div>
              </div>{" "}
              {/*adverterenRow */}
            </div>{" "}
            {/* AdverterenWrapper */}
            <div className={AdverterenAside}>
              <SideColumn showSponsorInfo={true} />
            </div>{" "}
            {/* AdverterenAside */}
          </div>{" "}
          {/* AdverterenAndSideWrapper */}
        </div>{" "}
        {/* container */}
      </main>
      <Footer />
    </Layout>
  )
}
export default Adverteren

export const pageQuery = graphql`
  query {
    fileSmb: file(relativePath: { eq: "mediakit-smallbusinessowner.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1180)
      }
    }

    fileDesk: file(relativePath: { eq: "mediakit-desk.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1180)
      }
    }

    fileHome: file(relativePath: { eq: "mediakit-home.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1180)
      }
    }

    site {
      siteMetadata {
        title
      }
    }

    navigationDesks: allContentfulDesk(sort: { fields: sortNavigation }) {
      nodes {
        id
        name
        slug2
        sortNavigation
        ctaNavigation {
          image {
            title
            gatsbyImageData(layout: CONSTRAINED, width: 1180)
          }
          title
          slug
          categories {
            slug2
            desk {
              slug2
            }
          }
        }
        deskcategory {
          id
          name
          slug2
          desk {
            id
            name
            slug2
          }
          article {
            id
            title
            slug2
            updateDate
            categories {
              slug2
              desk {
                slug2
              }
            }
          }
        }
      }
    }
  }
`
