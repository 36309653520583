import React from "react"
import SideColumnWrapper from "../side-column-wrapper/side-column-wrapper"
import {
  searchContainer,
  sideColumnTitle,
} from "./cta-search.module.css"
import { dataLayer } from "../../../modules/analytics"

const CtaSearch = ({ position }) => {
  const trackEvent = () => {
    dataLayer({
      event: "website-interaction",
      category: "call-to-action",
      action: "search",
      label: `position:side-column:${position}`,
    })
  }

  return (
    <SideColumnWrapper>
      <div className={searchContainer}>
        <p className={sideColumnTitle}>
          Heb je nog niet gevonden wat je zoekt?
        </p>
        <form action="/zoeken" onSubmit={trackEvent}>
          <input
            aria-label="Stel je ondernemersvraag"
            type="text"
            placeholder="Stel je ondernemersvraag"
            name="q"
            className="placeholderColor"
          />
          <button aria-label="Verstuur je zoekopdracht" type="submit">
            Zoeken
          </button>
        </form>
      </div>
    </SideColumnWrapper>
  )
}

export default CtaSearch
