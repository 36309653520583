// extracted by mini-css-extract-plugin
export var AdverterenAndSideWrapper = "adverteren-module--AdverterenAndSideWrapper--dd09f";
export var AdverterenAside = "adverteren-module--AdverterenAside--eb155";
export var AdverterenWrapper = "adverteren-module--AdverterenWrapper--ae381";
export var adverterenIntro = "adverteren-module--adverteren-intro--5fc6a";
export var adverterenRow = "adverteren-module--adverteren-row--8f23c";
export var adverterenSideColumnWrapper = "adverteren-module--adverteren-side-column-wrapper--af3a5";
export var border = "adverteren-module--border--51a45";
export var cijfer = "adverteren-module--cijfer--84f2e";
export var cijfers = "adverteren-module--cijfers--53e32";
export var imageWrapperWebsite = "adverteren-module--image-wrapper-website--6720f";
export var item = "adverteren-module--item--87e7c";
export var offwhite = "adverteren-module--offwhite--17cef";
export var table = "adverteren-module--table--285a6";
export var tableWrapper = "adverteren-module--table-wrapper--25ac6";
export var td = "adverteren-module--td--02fce";
export var th = "adverteren-module--th--b664f";
export var thead = "adverteren-module--thead--7a567";
export var tr = "adverteren-module--tr--6e754";