import React from "react"
import {
  KennisPartner,
  sideColumnTitle,
  text,
  link,
} from "./cta-sponsor-info.module.css"
import SideColumnWrapper from "../side-column-wrapper/side-column-wrapper"
import { dataLayer } from "../../../modules/analytics"

const CtaPlusMember = ({ position }) => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     file(relativePath: { eq: "coffee.png" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 359, maxHeight: 291, quality: 100) {
  //           ...GatsbyImageSharpFluid_withWebp_noBase64
  //         }
  //       }
  //     }
  //   }
  // `)

  const trackEvent = (data) => {
    dataLayer({
      event: "website-interaction",
      category: "call-to-action",
      action: "subscribe:navigate-to-sponsor-info",
      label: `position:side-column:${position}`,
    })
  }
  return (
    <SideColumnWrapper extraClass={KennisPartner}>
      <p className={sideColumnTitle}>Kennispartner worden?</p>
      <p className={text}>
        Wij organiseren betrouwbare, concrete en betaalbare antwoorden op alle
        ondernemersvragen. Dit doen wij samen met gerenommeerde kennispartners.
        Tijdens een structurele samenwerking zorgen we voor een gezamenlijke
        campagne waarin we ondernemers verder helpen.
      </p>
      <a
        className={link}
        href="mailto:sales@mkbservicedesk.nl"
        onClick={trackEvent}
      >
        Stuur een mail
      </a>
    </SideColumnWrapper>
  )
}

export default CtaPlusMember
