import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import SideColumnWrapper from "../side-column-wrapper/side-column-wrapper"
import {
  ExternalLink,
  ExternalLinkImageWrapper,
  SideColumnTitle,
  SideColumnText,
  body
} from "./cta-external-link.module.css"
import { trackExternalLink, eventTracking } from "../../../services/tracking"
import { getUid } from "../../../services/auth"
import { dataLayer } from "../../../modules/analytics"

const CtaExternalLink = ({ 
  cta, 
  position, 
  displayPageContentfulId, 
  displayPageContentfulTitle, 
  displayPosition 
}) => {
  const trackEvent = async () => {
    dataLayer({
      event: "website-interaction",
      category: "call-to-action",
      action: `external-link:${cta.url}`,
      label: `position:side-column:${position}`,
    })

    trackExternalLink(await getUid(), cta.contentful_id, cta.url)

    eventTracking("click", {
      contentfulId: cta.contentful_id,
      contentfulType: "externalLink",
      contentfulTitle: cta.title,
      displayPageContentfulId,
      displayPageContentfulTitle,
      displayPosition,
    })
  }

  return (
    <SideColumnWrapper>
      <div className={ExternalLink}>
        <p className={SideColumnTitle}>{cta.title}</p>

        <div className={body}>
          {(cta?.showSidebarCtaImage ?? true) && cta.image && (
            <GatsbyImage
              image={cta.image.gatsbyImageData}
              className={ExternalLinkImageWrapper}
              alt={cta.image.title ? cta.image.title : ""}
            />
          )}
          {cta.description && cta.description.childMarkdownRemark && (
            <p className={SideColumnText}>
              {cta.description.childMarkdownRemark.rawMarkdownBody}
            </p>
          )}
        </div>

        <a
          className="link"
          href={cta.url}
          onClick={trackEvent}
          target="_blank"
          rel="noopener noreferrer"
        >
          {cta.linkText || "Naar de website"}
        </a>
      </div>
    </SideColumnWrapper>
  )
}

export default CtaExternalLink
