import React, { useState, useEffect } from "react"
import firebase from "gatsby-plugin-firebase"
import { Formik, Form, Field, ErrorMessage } from "formik"
import SideColumnWrapper from "../side-column-wrapper/side-column-wrapper"
import {
  NewsLetter,
  NewsLetterInner,
  sideColumnTitle,
  formFieldWrapperOptin,
  formFieldError,
  mailchimpResponse,
  submitWrapper,
} from "./cta-newsletter.module.css"
import { getUser } from "../../../services/users"
import { signup } from "../../../services/newsletter"
import { trackEvent } from "../../../modules/analytics"

const CtaNewsLetter = ({ position }) => {
  position = position || 0
  const [mcResponse, setMcResponse] = useState(null)
  const [user, setUser] = useState({})

  const fieldsInitialValues = {
    optin: false,
    email: "",
  }

  const [initialValues, setInitialValues] = useState(fieldsInitialValues)

  useEffect(() => {
    if (!firebase) return
    firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) return await firebase.auth().signInAnonymously()
      const userData = await getUser(user.uid)
      if (userData) setInitialValues(userData)
      setUser(user)
    })
  }, [])

  const handleSubmit = async (formData) => {
    if (formData.email) {
      let response = await signup(user.uid, formData.email)
      setMcResponse(response)
      if (response) {
        trackEvent("cta_newsletter_signup")
      }
    }
  }

  return (
    <SideColumnWrapper>
      <div className={NewsLetter}>
        <div className={NewsLetterInner}>
          <p className={sideColumnTitle}>
            Elke week tips en inspiratie in je inbox!
          </p>

          <p className="text">
            Schrijf je in voor onze nieuwsbrief en ontvang elke week een mail
            met praktische informatie die je bedrijf écht vooruit helpt.
          </p>

          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="formFieldWrapper" key="email">
                <Field
                  validate={(value) =>
                    value ? undefined : "E-mailadres is verplicht"
                  }
                  className="formField"
                  placeholder="E-mailadres:"
                  type="email"
                  name="email"
                />
              </div>

              <div className={submitWrapper}>

                {!initialValues.optin && (
                  <div className={formFieldWrapperOptin} key="optin">
                    <label
                      className={`formFieldLabelOptin download-form-optin`}
                      htmlFor="optin"
                    >
                      Bij het inschrijven ga ik akkoord met de{" "}
                      <a
                        href="/algemene-voorwaarden/"
                        target="_blank"
                      >
                        Algemene voorwaarden
                      </a>{" "}
                      en de{" "}
                      <a href="/privacy-verklaring/" target="_blank">
                        Privacy Statement
                      </a>{" "}
                      en geef ik toestemming voor het ontvangen van nieuwsbrieven.
                    </label>
                    <Field
                      validate={(value) =>
                        value ? undefined : "Opt-in is nodig om verder te gaan"
                      }
                      className="formCheckbox"
                      type="checkbox"
                      name="optin"
                    />
                    <ErrorMessage
                      className={`${formFieldError} download-form-field-error`}
                      name="optin"
                      component="div"
                    />
                  </div>
                )}

                <button type="submit">Inschrijven</button>
              </div>
            </Form>
          </Formik>

          {mcResponse === true && (
            <p className={mailchimpResponse}>
              Bedankt! Je bent aangemeld voor de nieuwsbrief
            </p>
          )}

          {mcResponse === false && (
            <p className={mailchimpResponse}>
              Aanmelden mislukt. Probeer het opnieuw of gebruik een ander
              e-mailadres
            </p>
          )}
        </div>
      </div>
    </SideColumnWrapper>
  )
}

export default CtaNewsLetter
