import React from "react"
import PropTypes from "prop-types"

const SideColumnWrapper = ({ children, extraClass }) => {
  return <div>{children}</div>
}

SideColumnWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SideColumnWrapper
